<template>
    <div class="register">
        <el-form ref="form" :model="form" :rules="rules" class="form" label-width="80px" size="small">
            <el-form-item prop="oldPassword" label="旧密码">
                <el-input v-model="form.oldPassword"></el-input>
            </el-form-item>
            <el-form-item prop="newPassword" label="新密码">
                <el-input v-model="form.newPassword"></el-input>
            </el-form-item>
            <el-form-item label-width="0" class="btn-container">
                <el-button class="btn-login" type="primary" @click="handleClick">确认</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { modifyPwd } from '@/api/login.js';

export default {
    data(){
        return {
            form: {
                oldPassword: '',
                newPassword: ''
            },
            rules: {
                oldPassword: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                ],
            }
        }
    },
    methods: {
        handleClick(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    modifyPwd(this.form).then(res => {
                        this.$message.success('修改密码成功');
                        this.cancel();
                    })
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
            
        },
        keydown(event){
            if(event.keyCode === 13){
                this.handleRegister();
            }
        },
        cancel(){
            this.$emit('cancel');
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.register{
    .btn-container{
        display: flex;
        justify-content: center;
        .btn-login{
            width: 300px;
        }
    }
}
</style>