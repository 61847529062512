<template>
    <div class="register">
        <el-form ref="form" :model="form" :rules="rules" class="form" label-width="80px" size="small">
            <el-form-item prop="name" label="昵称">
                <el-input v-model="form.name"> </el-input>
            </el-form-item>
            <el-form-item prop="account" label="用户名">
                <el-input prefix-icon="el-icon-user" v-model="form.account"> </el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码">
                <el-input prefix-icon="el-icon-lock" v-model="form.password" @keydown.native="keydown">
                    <!-- <button class="pwd" type="button" slot="suffix" @click="open = !open" style="margin-right:5px;" ><i :class="pswSeen"></i></button> -->
                </el-input>
            </el-form-item>
            <el-form-item prop="role" label="角色">
                <el-select v-model="form.role" @keydown.native="keydown">
                    <el-option v-for="(item,key) in roleList" :key="item" :label="item" :value="key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label-width="0" class="btn-container">
                <el-button class="btn-login" type="primary" @click="handleRegister">注册</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { register } from '@/api/login.js';

export default {
    data(){
        return {
            form: {
                name: '',
                account: '',
                password: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入昵称",
                        trigger: "blur",
                    },
                ],
                account: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
            }
        }
    },
    computed: {
        roleList(){
            return this.$store.getters.configMap['whrp.user.roles'];
        }
    },
    methods: {
        handleRegister(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    register(this.form).then(res => {
                        this.$message.success('注册成功');
                        this.cancel();
                    })
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
            
        },
        keydown(event){
            if(event.keyCode === 13){
                this.handleRegister();
            }
        },
        cancel(){
            this.$emit('cancel');
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.register{
    .btn-container{
        display: flex;
        justify-content: center;
        .btn-login{
            width: 300px;
        }
    }
}
</style>