<template>
    <div class="user-list">
        <div class="search">
            <el-input size="small" v-model="params.keyword" placeholder="账号或昵称" clearable></el-input>
        </div>
         <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="name" label="昵称" width="180">
                <template slot-scope="scope">
                    <div>
                        <div v-if="editIndex === scope.$index">
                            <el-input size="mini" v-model="form.name"></el-input>
                        </div>
                        <div v-else>{{scope.row.name}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="role" label="角色" width="180"> 
                <template slot-scope="scope">
                    <div>
                        <div v-if="editIndex === scope.$index">
                            <el-select size="mini" v-model="form.role">
                                <el-option v-for="(item,key) in roleMap" :key="key" :label="item" :value="key"></el-option>
                            </el-select>
                        </div>
                        <div v-else>{{roleMap[scope.row.role]}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="account" label="账号"> </el-table-column>
            <el-table-column label="状态"> 
                <template slot-scope="scope">
                    <div>
                        <div v-if="editIndex === scope.$index">
                            <el-select size="mini" v-model="form.status">
                                <el-option v-for="(item,key) in statusMap" :key="key" :label="item" :value="Number(key)"></el-option>
                            </el-select>
                        </div>
                        <div v-else>{{statusMap[scope.row.status]}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="200"> 
                <template slot-scope="{ row }">
                    <div>{{Date.dateFormat(row.create_time)}}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200"> 
                <template slot-scope="{ row , $index }">
                    <div>
                        <el-button v-if="editIndex === $index" size="mini" type="success" @click="saveClick(row,$index)">保存</el-button>
                        <el-button v-hasPermit="'/api/user/modify'" v-else size="mini" type="primary" @click="editClick(row,$index)">编辑</el-button>
                        <el-button v-if="editIndex === $index" size="mini" type="info" @click="editIndex = -1">取消</el-button>
                        <el-button v-hasPermit="'/api/user/remove'" v-else size="mini" type="danger" @click="delClick(row)">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination background layout="total, sizes ,prev, pager, next , jumper" 
            :total="total" :current-page.sync="params.page" :page-size.sync="params.pageSize"> </el-pagination>
        </div>


    </div>
</template>

<script>
import { userList , removeUser , modifyUser } from '@/api/appAPI.js';

export default {
    data(){
        return {
            params: {
                page: 1,
                pageSize: 10,
                keyword: ''
            },
            tableData: [],
            total: 0,
            editIndex: -1,
            form: {
                name: '',
                role: '',
                status: ''
            },
            newKey: ''
        }
    },
    computed: {
        statusMap(){
            return this.$store.getters.configMap['whrp.user.status'];
        },
        roleMap(){
            return this.$store.getters.configMap['whrp.user.roles'];
        },
        pageParams(){
            return {
                page: this.params.page,
                pageSize: this.params.pageSize
            }
        }
    },
    created(){
        this.getTableData();
    },
    watch: {
        pageParams: {
            handler(){
                this.getTableData();
            },
            deep: true
        },
        'params.keyword': {
            handler(val){
                const newKey = Math.random();
                this.newKey = newKey;
                userList(this.params).then(res => {
                    if(newKey === this.newKey){
                        this.tableData = res.records;
                        this.total = res.total;
                    }
                })
            }
        }
    },
    methods: {
        getTableData(){
            userList(this.params).then(res => {
                this.tableData = res.records;
                this.total = res.total;
            })
        },
        editClick(row,index){
            this.editIndex = index;
            this.form.id = row.id;
            this.form.name = row.name;
            this.form.role = row.role;
            this.form.status = row.status;
        },
        delClick(row){
            this.$confirm('确认要删除用户么', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                removeUser({ id: row.id }).then(res => {
                    this.$message.success('删除成功');
                    this.getTableData();
                })
            }).catch(err => {
                console.log(err);
            });
        },
        saveClick(){
            modifyUser(this.form).then(res => {
                this.$message.success('保存成功');
                this.getTableData();
            }).finally(() => {
                this.editIndex = -1;
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-table .el-table__cell{
    padding: 10px 0;
}
.search{
    width: 240px;
    transform: translate(0,-50%);
}
.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
</style>