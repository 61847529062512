<template>
    <div class="layout">
        <div class="topbar-container">
            <div class="title" @click="toHome">应用管理平台</div>
            <div class="user-menu">
                <div class="register" @click="openUserListClick" v-hasPermit="'/api/user/list'">用户列表</div>
                <div class="register" @click="openRegisterClick" v-hasPermit="'/api/user/register'">注册账号</div>
                <el-dropdown>
                    <span class="avator">
                        <span class="user-name">{{realName}}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="editPwdClick" v-hasPermit="'/api/user/mod-pwd'">修改密码</el-dropdown-item>
                        <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="body">
            <router-view @editClick="editClick()"></router-view>
        </div>

        <el-dialog title="用户列表" :visible.sync="openUserList" width="1100px" :close-on-click-modal="false" top="10vh">
            <UserList v-if="openUserList" @cancel="openUserList = false"></UserList>
        </el-dialog>

        <el-dialog title="注册账号" :visible.sync="openRegister" width="500px" :close-on-click-modal="false">
            <Register v-if="openRegister" @cancel="openRegister = false"></Register>
        </el-dialog>

        <el-dialog title="修改密码" :visible.sync="openEditPwd" width="500px" :close-on-click-modal="false">
            <EditPwd @cancel="openEditPwd = false"></EditPwd>
        </el-dialog>


    </div>
</template>

<script>
import Register from './register';
import EditPwd from './editPwd';
import UserList from './userList';

export default {
    components: {
        Register,
        EditPwd,
        UserList
    },
    data(){
        return {
            open: false,
            openRegister: false,
            openEditPwd: false,
            editPwdForm: {

            },
            openUserList: false,
        }
    },
    computed: {
        realName(){
            return this.$store.getters.userInfo.name;
        }
    },
    methods: {
        toHome(){
            if(this.$route.name !== 'home'){
                this.$router.push({
                    name: 'home'
                })
            }
        },
        logout(){
            this.$store.dispatch('user/logout').finally(() => {
                this.$router.push({
                    name: 'login'
                })
            })
        },
        openRegisterClick(){
            this.openRegister = true;
        },
        editPwdClick(){
            this.openEditPwd = true;
        },
        openUserListClick(){
            this.openUserList = true;
        }   
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.layout{
    width: 100vw;
    height: 100vh;

    .topbar-container{
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
        padding: 0 20px;
        font-size: 16px;
        z-index: 10;
        position: relative;
        
        .title{
            color: #337ab7;
            cursor: pointer;
        }
        .user-menu{
            .register{
                color: #337ab7;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                margin-right: 15px;
            }
            .avator{
                display: flex;
                align-items: center;
                // color: #fff;
                cursor: pointer;
                img{
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
                .user-name{
                    margin: 0 10px;
                }
            }
        }
    }
    .body{
        height: calc(100% - 40px);
        box-sizing: border-box;
        // padding-bottom: 20px;
        // padding: 0 10%;
    }
}
</style>